import {
  forwardRef,
  useEffect,
  useId,
  useLayoutEffect,
  useRef,
  useState,
  type ChangeEvent,
  type ComponentProps,
  type HTMLInputTypeAttribute,
} from "react";

import cn from "../../util/cn.js";
import type { ComponentLabelWrapperProps } from "../ComponentLabelWrapper/ComponentLabelWrapper.js";
import ComponentLabelWrapper from "../ComponentLabelWrapper/ComponentLabelWrapper.js";
import Wrapper from "../Wrapper/Wrapper.js";

const DEFAULT_MAX_HEIGHT_PX = 400;

export interface TextAreaProps extends ComponentProps<"textarea"> {
  containerClassName?: string;
  type?: HTMLInputTypeAttribute;
  placeholder?: string;
  inputStyle?: "NORMAL" | "CHROMELESS" | "DEEMPHASIZED";
  inputClassName?: string;
  rows?: number;
  value?: string;
  maxRows?: number;
  minRows?: number;
  hidden?: boolean;
  maxHeightPx?: number;
  autoResize?: boolean;
  label?: Omit<ComponentLabelWrapperProps, "htmlFor">;
}

const useIsoEffect = typeof window === "undefined" ? useEffect : useLayoutEffect;

const TextArea = forwardRef<HTMLTextAreaElement, TextAreaProps>(
  (
    {
      containerClassName,
      inputClassName,
      hidden,
      inputStyle,
      disabled,
      readOnly,
      autoResize,
      onChange,
      id,
      label,
      maxHeightPx,
      ...props
    },
    ref
  ) => {
    const [textAreaHeight, setTextAreaHeight] = useState("auto");

    const internalRef = useRef<HTMLTextAreaElement | null>(null);

    const generatedId = useId();
    id = id ?? generatedId;

    const setRefs = (element: HTMLTextAreaElement | null) => {
      internalRef.current = element;

      if (typeof ref === "function") {
        ref(element);
      } else if (ref) {
        ref.current = element;
      }
    };

    useIsoEffect(() => {
      if (autoResize) {
        if (props.value === "") {
          setTextAreaHeight("auto");
        } else if (internalRef.current) {
          const height = Math.min(
            internalRef.current.scrollHeight,
            maxHeightPx ?? DEFAULT_MAX_HEIGHT_PX
          );
          setTextAreaHeight(`${height}px`);
        }
      }
    }, [props.value, autoResize]);

    /*
     * Need this to stop perpetual resizing of textArea on key input due to padding
     * Also to properly resize on text deletion, a reset to 'auto' is needed
     */
    const onChangeHandler = (event: ChangeEvent<HTMLTextAreaElement>) => {
      if (autoResize) {
        setTextAreaHeight("auto");
      }

      if (onChange) {
        onChange(event);
      }
    };

    return (
      <div className={cn("input-wrap relative", containerClassName)}>
        <Wrapper
          condition={!!label}
          wrapper={(c) => (
            <ComponentLabelWrapper {...label!} htmlFor={id}>
              {c}
            </ComponentLabelWrapper>
          )}
        >
          <textarea
            {...props}
            id={id}
            disabled={disabled}
            ref={setRefs}
            onChange={onChangeHandler}
            className={cn(
              "block w-full rounded-md placeholder:text-secondary/50 overflow-auto",
              {
                "border border-base bg-surface text-primary placeholder-secondary focus:border-accent focus:outline-none focus:ring-accent sm:text-sm":
                  inputStyle === "NORMAL",
                "border-none bg-transparent p-0 focus:border-0 focus:outline-none focus:ring-0":
                  inputStyle === "CHROMELESS",
                "border text-sm focus:border-accent focus:ring-accent":
                  inputStyle === "DEEMPHASIZED",
              },
              {
                hidden: hidden,
              },
              {
                "bg-surface-muted text-secondary cursor-not-allowed": disabled || readOnly,
              },
              "focus:ring-0",
              inputClassName
            )}
            style={{ height: textAreaHeight }}
          />
        </Wrapper>
      </div>
    );
  }
);

export default TextArea;
